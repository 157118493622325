import PropTypes from 'prop-types'
import React from 'react'
import Fade from 'react-reveal/Fade'

const Tiles = ({ animateScrollIntoView = false, children }) => (
  animateScrollIntoView ?
    <div className="card-columns"><Fade bottom distance={'50px'}>{children}</Fade></div>
    :
    <div className="card-columns">{children}</div>
)

export default Tiles

Tiles.propTypes = {
  animateScrollIntoView: PropTypes.bool,
  children: PropTypes.any,
}

Tiles.defaultProps = {
  animateScrollIntoView: false,
}
