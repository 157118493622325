import PropTypes from 'prop-types'
import React from 'react'

const JobCategory = ({ title }) => (<div className="my-3"><h2 className="h4">{title}</h2></div>)

export default JobCategory

JobCategory.propTypes = {
  title: PropTypes.string,
}
