import PropTypes from 'prop-types'
import React from 'react'
import NavigationLink from './NavigationLink'
import classNames from 'classnames'

const JobListItem = ({ linkTo, jobTitle, noBorder = false }) => (
  <div className="row">
    <div className={classNames('col py-3', { 'border-top': !noBorder })}>
      <p><NavigationLink to={linkTo} className="display-1">{jobTitle}</NavigationLink></p>
    </div>
  </div>
)

export default JobListItem;

JobListItem.propTypes = {
  jobTitle: PropTypes.string,
  linkTo: PropTypes.string
}
