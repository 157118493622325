import React from 'react'
import classNames from 'classnames'

const Tile = ({ title, children, className }) => (
  <div className={classNames('card border-0 p-3', className)}>
    <div className="card-body">
      {title &&
      <h4 className="card-title mb-4">{title}</h4>
      }
      <div className="text-muted">
      {children}
      </div>
    </div>
  </div>
)

export default Tile
